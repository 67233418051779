import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faArrowDown} from "@fortawesome/free-solid-svg-icons";
import BookAppointment from "../components/BookAppointment/BookAppointment";
import Accordion from "../components/Accordion/Accordion";

const IntelligenceTests = () => {

    return <>
        <div className="service-banner-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="servicesTitle">
                            <div className="cell-view">
                                <h1 className="h1 as">Intelligenstest</h1>
                                <div className="breadCrumbs small">
                                    <a href="/">hjem</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}}
                                    icon={faAngleRight}/>
                                    <a href="/undersoegelser">undersøgelser</a><FontAwesomeIcon
                                    style={{marginLeft: 10, marginRight: 10}} icon={faAngleRight}/>
                                    <span>Intelligenstest</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="contentPadding">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="mainServicesContent">
                            <div className="blogWrapper">
                                <div className="blogContent">
                                    <div className="simple-article normall">
                                        <h5>Intelligenstestning af børn og unge</h5>
                                        <p>Jeg tilbyder intelligenstestning (kognitiv testning) af børn og unge i alderen 6-16 år.<br/>En intelligenstest giver viden om barnets forudsætninger for at lære og kan således give en forståelse af, hvordan barnet lærer bedst.</p>
                                        <p>Jeg anvender den mest udbredte og anerkendte intelligenstest til børn og unge; Wechsler Intelligence Scale for Children – fifth edition (WISC-V). Denne test undersøger en bred vifte af barnets kognitive færdigheder og giver således både viden om specifikke kognitive færdigheder og en samlet IQ. Testen foregår hovedsaligt på Ipad, hvor barnet bliver præsenteret for en række opgaver.</p>
                                        <p>De fleste børn og unge synes, at det er spændende og sjovt at lave en intelligenstest, og jeg sørger altid for, at det foregår i et hyggeligt og afslappet miljø, hvor barnet har mulighed for at præstere på bedst mulig vis.</p>
                                        <p>Hvornår kan en intelligenstest være relevant for dit barn?</p>


                                        <br/>
                                        <h6>Udfordringer i skolen</h6>
                                        <p>Oplever dit barn faglige udfordringer i skolen? Er det svært for dit barn at følge med, og kommer dit barn mere og mere bagud fagligt? Virker det som om, barnet har svært ved at lære? – Så kan en intelligenstest være vejen til at forstå dit barns udfordringer.</p>
                                        <p>Der kan være mange årsager til, at et barn har svært ved at lære i skolen. En intelligenstest kan pege på, om barnet har indlæringsvanskeligheder, eller om det er nødvendigt at se på andre årsager til udfordringerne. Under alle omstændigheder vil en intelligenstestning give viden om, hvordan barnet lærer bedst.</p>

                                        <br/>
                                        <h6>Udredning for en diagnose</h6>
                                        <p>Har du mistanke om, at dit barn har en diagnose? Eller venter dit barn på en udredning i offentligt regi? – Så kan en intelligenstest i privat regi gøre udredningsprocessen kortere.</p>
                                        <p>Hvis dit barn skal udredes for en diagnose, vil det i de fleste tilfælde kræve en intelligenstest. I det offentlige system er der lige nu mere end 1 års ventetid på en udredning, hvorfor flere og flere vælger at få lavet testen i privat regi. Hos mig vil I inden for kort tid kunne få udarbejdet en intelligenstest, så I kan komme hurtigere igennem systemet. I de tilfælde, hvor det er relevant, og I ønsker det, kan jeg ligeledes supplere med relevante interviews og spørgeskemaer til undersøgelse af den specifikke diagnose.</p>

                                        <br/>
                                        <h6>Højtbegavet barn</h6>

                                        <p>Har dit barn altid været hurtig til at lære? Virker det som om, at dit barn har flair for de fleste ting, barnet begiver sig ud i? Har du mistanke om, at dit barn er højtbegavet? – Så kan en intelligenstest være vejen til at forstå dit barn endnu bedre og give dit barn de bedste muligheder for at udnytte sit potentiale.</p>
                                        <p>Der er i de seneste år kommet et større fokus på højtbegavede børn, og hvordan disse børn hjælpes til på bedst mulig vis at udnytte deres potentiale. De fleste skoler har dog stadig ikke tilstrækkelig viden om, hvordan højtbegavede børn skal forstås, og hvordan eksempelvis en asynkron udvikling hos disse børn kan resultere i misforståelser og frustration for alle parter. Det kan derfor være relevant med en intelligenstest for at kende barnets kognitive profil og indlæringsmæssige forudsætninger, så barnet mødes på bedst mulig vis.</p>
                                        <p>På de fleste skoler for højtbegavede børn kræves som regel en intelligenstest for at blive optaget.</p>

                                        
                                        <br/>
                                        <h6>Nysgerrig på hvordan dit barn lærer bedst</h6>
                                        <p>Er du nysgerrig på, hvilke forudsætninger dit barn har for at lære, og hvordan det lærer bedst? -  Så kan det også være relevant med en intelligenstest.</p>
                                        
                                        <br id="pakker_priser"/>
                                        <h6>Pakker og priser på Intelligenstestning</h6>
                                        <p>Hvis du ønsker en intelligenstestning af dit barn, kan du vælge mellem 3 pakker:</p>
                                        <div className="accordeon normall">
                                            <Accordion headerText={"Pakke 1"}>
                                                <div className="simple-article-text-box-wrapper" style={{border: "none", margin: 0, padding: "10px 0px"}}>
                                                    <ul >
                                                            <li style={{fontWeight: 500}}>1 times forsamtale med forældrene, hvor der spørges ind til barnets opvækst og barnets styrker og svagheder.</li>
                                                            <li style={{fontWeight: 500}}>1½-2 timers intelligenstestning inklusiv en indledende samtale med barnet</li>
                                                            <li style={{fontWeight: 500}}>Opgørelse, scoring og tolkning af testens resultater</li>
                                                            <li style={{fontWeight: 500}}>½-1 times mundtlig tilbagemelding til forældrene og evt. barnet</li>
                                                            <li style={{fontWeight: 500}}>Rapport med testens resultater</li>
                                                    </ul>
                                                </div>
                                                <b>Pris: fra 9.500 kr.</b>
                                            </Accordion>
                                            <Accordion headerText={"Pakke 2"}>
                                            <div className="simple-article-text-box-wrapper" style={{border: "none", margin: 0, padding: "10px 0px"}}>
                                                    <ul>
                                                        <li>1 times forsamtale med forældrene, hvor der spørges ind til barnets opvækst og barnets styrker og svagheder.</li>
                                                        <li style={{fontWeight: 500}}>Ca. 30 min. lærerinterview</li>
                                                        <li>1½-2 timers intelligenstestning inklusiv en indledende samtale med barnet</li>
                                                        <li>Opgørelse, scoring og tolkning af testens resultater</li>
                                                        <li>½-1 times mundtlig tilbagemelding til forældrene og evt. barnet</li>
                                                        <li>Rapport med testens resultater</li>
                                                        <li style={{fontWeight: 500}}>Fyldstgørende sammenfatning af testens resultater</li>
                                                        <li style={{fontWeight: 500}}>Skriftlige anbefalinger til konkrete handletiltag (kan udleveres til barnets skole)</li>
                                                    </ul>
                                                </div>
                                                <b>Pris: fra 11.500 kr.</b>
                                            </Accordion>
                                            <Accordion headerText={"Pakke 3"}>
                                            <div className="simple-article-text-box-wrapper" style={{border: "none", margin: 0, padding: "10px 0px"}}>
                                                    <ul>
                                                        <li>1 times forsamtale med forældrene, hvor der spørges ind til barnets opvækst og barnets styrker og svagheder.</li>
                                                        <li>Ca. 30 min. lærerinterview</li>
                                                        <li>1½-2 timers intelligenstestning inklusiv en indledende samtale med barnet</li>
                                                        <li>Opgørelse, scoring og tolkning af testens resultater</li>
                                                        <li>½-1 times mundtlig tilbagemelding til forældrene og evt. barnet</li>
                                                        <li>Rapport med testens resultater</li>
                                                        <li>Fyldstgørende sammenfatning af testens resultater</li>
                                                        <li>Skriftlige anbefalinger til konkrete handletiltag (kan udleveres til barnets skole)</li>
                                                        <li style={{fontWeight: 500}}>Deltagelse i netværksmøde med skolen, hvor testresultaterne og anbefalinger gennemgås</li>
                                                    </ul>
                                                </div>
                                                <b>Pris: fra 14.000 kr. (eksl. transportomkostninger)</b>
                                            </Accordion>
                                        </div>
                                        <p>Hvor det er relevant, kan pakkerne suppleres med beskrivelser og spørgeskemabesvarelser fra barnets netværk, der kan give en indikation af, om barnet har en diagnose.</p>
                                        <p>Er dit barn allerede blevet testet, men mangler I som forældre en forklaring af testens resultater, er I også velkomne til at kontakte mig. Efter aftale kan jeg endvidere udarbejde anbefalinger eller deltage i netværksmøde med skolen og forklare resultaterne. Kontakt mig gerne for at høre nærmere.</p>
                                    </div>
                                </div>
                                <BookAppointment/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>;
}
export default IntelligenceTests;